import { Component, OnInit } from '@angular/core';

import { ModalController, AlertController } from '@ionic/angular';

import { StateService } from '../state.service';

@Component({
  selector: 'app-add-other-person',
  templateUrl: './add-other-person.page.html',
  styleUrls: ['./add-other-person.page.scss'],
})
export class AddOtherPersonPage implements OnInit {

  FormInfo: any = {};

  constructor(
    public modalController: ModalController,
    public state: StateService,
  ) { }

  ngOnInit() {
  }

  Dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss({
      'dismissed': true
    });
  }

  Save() {
    this.state.OtherPersons.push(this.FormInfo)
    this.Dismiss();
  }

}
