import { Component, OnInit } from '@angular/core';

import { ModalController, AlertController } from '@ionic/angular';

import { StateService } from '../state.service';

@Component({
  selector: 'app-add-director',
  templateUrl: './add-director.page.html',
  styleUrls: ['./add-director.page.scss'],
})
export class AddDirectorPage implements OnInit {

  FormInfo: any = {};

  constructor(
    public modalController: ModalController,
    public state: StateService,
  ) { }

  ngOnInit() {
  }

  Dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss({
      'dismissed': true
    });
  }

  Save() {
    this.state.Directors.push(this.FormInfo)
    this.Dismiss();
  }

}
